let itemsClick = ".promo-community__navigation_item"
let menuBox = ".promo-community__navigation_menu-box"

let menuBoxVisible = $(menuBox).css({visibility: "visible"})

$('.cabinet-link')
    .each((i, el) => {
        el.href += (new URL(window.location.href)).search
    })

$(itemsClick).eq(0).click(function (){
    $('html, body').animate({
        scrollTop: $(".promo-community__target .promo-community__title").offset().top- 110}, 1000);

});

$(itemsClick).eq(1).click(function (){
    $('html, body').animate({
        scrollTop: $(".promo-community__draw-places .promo-community__title").offset().top - 110}, 1000);

});

/*$(itemsClick).eq(2).click(function (){
    $('html, body').animate({
        scrollTop: $(".promo-community__market").offset().top
    }, 1000);

});*/
$(itemsClick).eq(2).click(function (){
    $('html, body').animate({
        scrollTop: $(".promo-community__sponsors .promo-community__title").offset().top - 110
    }, 1000);

});

$(document).ready(function() {
    $('.preloader, .overlay').fadeOut();
});
$(window).enllax();


$( ".promo-community__navigation_mobile-menu-burger" ).click(function() {
    $(menuBox).css({visibility: "visible"})
});

$( ".promo-community__navigation_mobile-menu-close" ).click(function() {
    $(menuBox).css({visibility: "hidden"})
});



if ($(window).width() <= 959) {
    $(itemsClick).click(function() {
        $(menuBox).css({visibility: "hidden"})
    });
}
else{
    $(itemsClick).click(function() {
        $(menuBox).css({visibility: "visible"})
    });
}



$(window).on('resize', function(){
    let win = $(this);
    if (win.width() <= 959) {
        $(itemsClick).click(function() {
            $(menuBox).css({visibility: "hidden"})
        });
    }
         else{
        $(itemsClick).click(function() {
            $(menuBox).css({visibility: "visible"})
        });
    }
});

if ($(window).width() > 959) {
    $(menuBox).css({visibility: "visible"})
}
else{
    $(menuBox).css({visibility: "hidden"});

}
$(window).on('resize', function(){
    let win = $(this);
    if (win.width() > 959) {
        $(menuBox).css({visibility: "visible"})
    }
    else{
        $(menuBox).css({visibility: "hidden"})
    }
});
